import React from "react";
import { Currency } from "./PriceFormat.types";

interface PriceFormatProps {
  value: number;
  locale?: string;
  currency?: Currency;
}

export const roundFloat = (number: number, decimalPlaces: number): number => {
  return +(Math.round(Number(number + `e+${decimalPlaces}`)) + `e-${decimalPlaces}`);
};

export const PriceFormat: React.FC<PriceFormatProps> = ({ value, currency, locale = "en-US" }) => {
  if (!currency) {
    currency = Currency.EUR;
  }
  const roundValue = Number(roundFloat(value, 2));
  const withCurrency = roundValue.toLocaleString(locale, { style: "currency", currency });

  const noTrailingZerosRegExp: RegExp = /\.00|,00/;

  return withCurrency.replace(noTrailingZerosRegExp, "");
};
