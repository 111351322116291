export enum Currency {
  PLN = "PLN",
  USD = "USD",
  EUR = "EUR",
  SEK = "SEK",
  GBP = "GBP",

  //legacy
  EURO = "EUR",
}
