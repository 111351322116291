import { css } from "@emotion/react";
import { spacing, colors } from "../../core/styles";

export const styles = {
  card: (disableSideGutters?: boolean) =>
    css({
      borderRadius: spacing.baseUnitMobile1,
      border: `1px solid ${colors.disabledGrey.hex}`,
      backgroundColor: colors.white.hex,
      padding: `${spacing.baseUnitMobile2} ${disableSideGutters ? "0" : spacing.baseUnitMobile2}`,
    }),
};
