export const VAT = {
  fr: 0.2,
  de: 0.19,
  it: 0.22,
  nl: 0.21,
  pl: 0.23,
  ru: 0,
  sl: 0.22,
  es: 0.21,
  sv: 0.25,
  en: 0.21,
  default: 0.23,
};

const getVatForCountry = (country: string) => VAT[country] ?? VAT.default;

export const calculateVat = (country: string, price?: number) => {
  const vatPercentage = getVatForCountry(country);
  return price ? Number((price * vatPercentage).toFixed(2)) : undefined;
};

export const vatUtil = { getVatForCountry, calculateVat };
