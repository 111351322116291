import { css } from "@emotion/react";
import { content, mediaQuery, spacing } from "../../core";

export const styles = {
  container: (
    noPaddingMobile: boolean,
    noPaddingDesktop: boolean,
    paddingTopMobile?: keyof typeof spacing,
    paddingTopDesktop?: keyof typeof spacing,
    paddingBottomMobile?: keyof typeof spacing,
    paddingBottomDesktop?: keyof typeof spacing
  ) =>
    css({
      margin: `0 auto`,
      maxWidth: content.width,

      [mediaQuery.noneDesktop]: {
        paddingLeft: noPaddingMobile ? 0 : spacing.baseUnitMobile2,
        paddingRight: noPaddingMobile ? 0 : spacing.baseUnitMobile2,
        paddingTop: paddingTopMobile && spacing[paddingTopMobile],
        paddingBottom: paddingBottomMobile && spacing[paddingBottomMobile],
      },

      [mediaQuery.desktopOrLarger]: {
        paddingLeft: noPaddingDesktop ? 0 : spacing.baseUnitMobile3,
        paddingRight: noPaddingDesktop ? 0 : spacing.baseUnitMobile3,
        paddingTop: paddingTopDesktop && spacing[paddingTopDesktop],
        paddingBottom: paddingBottomDesktop && spacing[paddingBottomDesktop],
      },
    }),
};
