import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, spacing } from "../../../../core/styles";

export const styles = {
  root: (isActive) =>
    css({
      display: "flex",
      minHeight: "38px",
      cursor: "pointer",
      borderRadius: "4px",
      textAlign: "center",
      fontWeight: isActive ? "bold" : "normal",
      backgroundColor: isActive ? colors.white.hex : colors.disabledGrey.hex,
      padding: spacing.baseUnitMobile1,
      flex: "1 1 162px",
    }),
  text: () =>
    css({
      margin: "auto",
      lineHeight: lineHeights.body.cat,
      fontSize: fontSizes.body.cat,
      letterSpacing: letterSpacings.body.cat,
      color: colors.linkBlue.hex,
    }),
};
