import React, { ReactNode } from "react";
import { styles } from "./Card.style";

export interface CardProps {
  children: ReactNode;
  className?: string;
  disableSideGutters?: boolean;
}

export const Card: React.FC<CardProps> = ({ children, className, disableSideGutters }: CardProps) => {
  return (
    <div css={styles.card(disableSideGutters)} className={className}>
      {children}
    </div>
  );
};
