import React from "react";
import { styles } from "./TabToggle.style";

interface TabToggleProps {
  title: string;
  id: string;
  activeTabId: string;
  setActiveTabId: (id: string) => void;
  testSelector?: string;
}

export const TabToggle: React.FC<TabToggleProps> = ({
  title,
  id,
  activeTabId,
  setActiveTabId,
  testSelector,
}: TabToggleProps) => {
  const isActive = id === activeTabId;

  const handleClick = () => {
    if (!isActive) {
      setActiveTabId(id);
    }
  };

  return (
    <div data-testid={testSelector} css={styles.root(isActive)} onClick={handleClick}>
      <span css={styles.text()}>{title}</span>
    </div>
  );
};
