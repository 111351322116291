import React, { ReactNode } from "react";
import { styles } from "./TabToggleGroup.style";

export interface TabToggleGroupProps {
  children: ReactNode;
}

export const TabToggleGroup: React.FC<TabToggleGroupProps> = ({ children }: TabToggleGroupProps) => {
  return <div css={styles.root()}>{children}</div>;
};
