import React, { PropsWithChildren } from "react";
import { styles } from "./Container.style";
import { spacing } from "../../core";

type SpacingKey = keyof typeof spacing;

export interface ContainerProps {
  noPaddingMobile?: boolean;
  noPaddingDesktop?: boolean;
  /**
   * Put here string with spacing name or [mobilePadding, desktopPadding] array
   */
  paddingTop?: SpacingKey | [SpacingKey, SpacingKey];
  paddingBottom?: SpacingKey | [SpacingKey, SpacingKey];
}

export const Container: React.FC<PropsWithChildren<ContainerProps>> = (props) => {
  const {
    children,
    noPaddingMobile = false,
    noPaddingDesktop = false,
    paddingTop = undefined,
    paddingBottom = undefined,
  } = props;
  const getPaddingTop = (): [SpacingKey, SpacingKey] => {
    return getPadding(paddingTop);
  };
  const getPaddingBottom = (): [SpacingKey, SpacingKey] => {
    return getPadding(paddingBottom);
  };

  const getPadding = (padding: SpacingKey | [SpacingKey, SpacingKey]): [SpacingKey, SpacingKey] => {
    if (typeof padding === "string") {
      return [padding, padding];
    } else if (Array.isArray(padding) && padding.length < 3) return padding;
    return [undefined, undefined];
  };

  return (
    <div css={styles.container(noPaddingMobile, noPaddingDesktop, ...getPaddingTop(), ...getPaddingBottom())}>
      {children}
    </div>
  );
};
