import { css } from "@emotion/react";

import { colors } from "../../core/styles";

export const styles = {
  root: () =>
    css({
      display: "inline-flex",
      borderRadius: "6px",
      padding: "2px",
      listStyle: "none",
      background: colors.disabledGrey.hex,
      minHeight: "38px",
      flex: "0 1 328px",
    }),
};
