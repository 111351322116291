import { LanguageResolver } from "i18n/language-resolver";
import { BackendApi } from "../backend-api/backend-api";
import { CreditsPackage, IEngineData, IPackagesPricing, IPricingResponse } from "./types";
import { ApiConfig } from "../backend-api/api-config";
import { ApiRoutes } from "../backend-api/api-routes";
import { EHttpStatus } from "utils/backend-api/error-types";
import { RecordCheckErrorMessages } from "./RecordCheckErrorMessages";
import { PriceDetails } from "../../stores/checkout.store";
import { withRecaptcha } from "domain/Recaptcha/withRecaptcha";
import BackendApiError from "utils/backend-api/backend-api-error";
import RecaptchaActions from "domain/Recaptcha/actions";

interface Token {
  "g-recaptcha-token"?: string;
  recaptchaToken?: string;
}
export class PricingApi {
  public backendApi: BackendApi;
  constructor() {
    this.backendApi = new BackendApi(ApiConfig.buildBackendUrlForClient());
  }

  public getPricingResponseParams(params) {
    return {
      ...params,
      lang: LanguageResolver.getCurrentLanguageShort(),
    };
  }

  public fetchPricingResponseForTransaction(vin: string, params) {
    return this.backendApi.request<IPricingResponse>(ApiRoutes.recordCheckForTransaction(vin), params);
  }

  public fetchEngineDataResponse(vin: string, params) {
    return this.backendApi.request<IEngineData>(ApiRoutes.recordCheckEngineData(vin), params);
  }

  public fetchPricingResponseForTransactionForEncryptedVinreg(vin: string, params) {
    return this.backendApi.request<IPricingResponse>(
      ApiRoutes.recordCheckdForTransactionForEncryptedVinreg(vin),
      params
    );
  }

  public async fetchPricing(vin: string, token: Token) {
    const params = this.getPricingResponseParams(token);
    return this.fetchPricingResponseForTransaction(vin, params);
  }

  private async fetchPricingForEncryptedVinreg(vin: string, token: Token) {
    const params = this.getPricingResponseParams(token);
    return this.fetchPricingResponseForTransactionForEncryptedVinreg(vin, params);
  }

  public async fetchPricingResponseForVin(vin: string, action = RecaptchaActions.vinsearch): Promise<IPricingResponse> {
    try {
      return await withRecaptcha({ action })((tokens) => this.fetchPricing(vin, { ...tokens }));
    } catch (error) {
      if (error instanceof BackendApiError) {
        throw this.mapHttpCodeToErrorMessage(error.status);
      }
      throw error;
    }
  }

  public async fetchPricingResponseForEncryptedVin(
    vin: string,
    action = RecaptchaActions.vinsearch
  ): Promise<IPricingResponse> {
    try {
      return await withRecaptcha({ action })((tokens) => this.fetchPricingForEncryptedVinreg(vin, { ...tokens }));
    } catch (error) {
      if (error instanceof BackendApiError) {
        throw this.mapHttpCodeToErrorMessage(error.status);
      }
      throw error;
    }
  }

  public async fetchEngineDataResponseWithCaptcha(
    vin: string,
    action = RecaptchaActions.vinsearch
  ): Promise<IEngineData> {
    try {
      return await withRecaptcha({ action })((tokens) => this.fetchEngineData(vin, { ...tokens }));
    } catch (error) {
      if (error instanceof BackendApiError) {
        throw this.mapHttpCodeToErrorMessage(error.status);
      }
      throw error;
    }
  }

  public fetchPackagesPricing(lang: string) {
    return this.backendApi.request<IPackagesPricing>(ApiRoutes.packagesUrl(lang));
  }

  public fetchEngineData(vin: string, token: Token): Promise<IEngineData> {
    const params = this.getPricingResponseParams(token);
    return this.fetchEngineDataResponse(vin, params);
  }

  public fetchBusinessPackagesPricing(lang: string) {
    return this.backendApi.request<IPackagesPricing>(ApiRoutes.businessPackagesUrl(lang));
  }

  public fetchPartnerPackage(lang: string, packageName: string) {
    return this.backendApi.request<CreditsPackage>(ApiRoutes.partnerPackagesUrl(lang, packageName));
  }

  public fetchBasicPackageDataByName(lang: string, packageName: string) {
    return this.backendApi.request<CreditsPackage>(ApiRoutes.basicPackagesUrl(lang, packageName));
  }

  public getNetPrice(transactionId: string, country: string, viesResult: boolean) {
    return this.backendApi.request<PriceDetails>(ApiRoutes.netAmount(transactionId, country, viesResult));
  }

  public getCustomerNetPrice(transactionId: string, country: string) {
    return this.backendApi.request<PriceDetails>(ApiRoutes.customerNetAmount(transactionId, country));
  }

  public mapHttpCodeToErrorMessage(statusError: number): RecordCheckErrorMessages {
    switch (statusError) {
      case EHttpStatus.NotFound:
        return RecordCheckErrorMessages.VIN_NOT_FOUND;
      case EHttpStatus.Conflict:
        return RecordCheckErrorMessages.REPORT_ALREADY_BOUGHT;
      case EHttpStatus.Forbidden:
        return RecordCheckErrorMessages.RECAPTCHA;
      default:
        return RecordCheckErrorMessages.FETCH_FAILED;
    }
  }
}

export default new PricingApi();
